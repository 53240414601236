import { useState } from 'react';
import mockApi from '../api/mockApi';

export default function useUserData() {
    const [userData, setUserData] = useState({
		name: "unknown",
		id: "0",
		ev_powers: [0, 1380, 1610, 1840, 2070, 2300, 2530, 2760, 2990, 3220, 3450, 3680, 3910, 4140, 4370, 4600, 4830, 5060, 5290, 5520, 5750, 5980, 6210, 6440, 6670, 6900, 7130, 7360],
		ev_consumption: 194,
		ev_capacity: 0,
		session_state: "unknown",
		session_progress: 0,
		session_energy: 0,
		session_power: 0,
		session_kilometers: 0,
		session_energy_target: 0,
		session_kilometers_target: 0,
		session_modus: "unknown",
		session_minutes: 0,
		session_hours: 0,
	});
	const id = "0";

    const getUserData = async (id) => {
        try {
            const response = await mockApi.get('/getuser/'+id);
            //setUserData(response.data);
			setUserData(prevState => ({
                ...prevState,
                name: response.data.name,
				id: response.data.id,
				ev_powers: response.data.ev_powers,
				ev_consumption: response.data.ev_consumption,
				ev_capacity: response.data.ev_capacity,
				session_state: response.data.session_state,
				session_progress: response.data.session_progress,
				session_energy: response.data.session_energy,
				session_power: response.data.session_power,
				session_kilometers: response.data.session_kilometers,
				session_energy_target: response.data.session_energy_target,
				session_kilometers_target: response.data.session_kilometers_target,
				session_modus: response.data.session_modus,
				session_minutes: response.data.session_minutes,
				session_hours: response.data.session_hours,
            }));
        } catch (error) {
            console.log(error)
        }
    };

    return {userData: userData, getUserData: getUserData};
};

