import { useState } from 'react';
import mockApi from '../api/mockApi';

export default function useProfileData() {
    const [profileData, setProfileData] = useState({
		desired_profile: [],
		intervals: [],
	});

    const getProfileData = async () => {
        try {
            const response = await mockApi.get('/incentive');
            setProfileData(
			prevState => ({
                ...prevState,
				desired_profile: response.data.desired_profile,
				intervals: response.data.intervals,
			}));
        } catch (error) {
            console.log(error)
        }
    };

    return {profileData: profileData, getProfileData: getProfileData};
};
