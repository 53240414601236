import React, {useCallback, useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row, Alert } from 'reactstrap';
import EvCar from '../../assets/ev_car.svg';
import '../../styles/lightMode.scss';
import '../../styles/start.scss';
import { GlobalButton } from '../styled/Button';

import { setCookie, getCookie } from "../../data/Cookies";
import useUserData from "../../hooks/useUserData.js";


interface StartProps {name?: string}


// Landing page
export default function Start({name, ...props}:StartProps) {
    const history = useHistory();
	const [alert, setAlert] = useState<boolean>(false);
	
	let userName = "unknown";
	
	// First check if we have a login token or not
	// We try to read the parameter
	let { id } = useParams<{id: string}>();
	
	if ( typeof id === "undefined" ) {
		//In case it does not exist (i.e. not set), we know we need to 
		// @ts-ignore
		id = getCookie("id", id); 
		
		if ( typeof id === "undefined" ) {
			// If still undefined, we have an unknwon (unwanted state):
			id = "0";
		}
	}
	else {
		// We have a new "login", store the state
		setCookie("id", id); // FIXME: Need to set the id rather then a name
	}
	
	
	// Retrieve info from server
	const { userData, getUserData } = useUserData();
	
	useEffect(() => {
		getUserData(id);
    	}, [id])
	

	// Select the proper page
	useEffect(() => {
		let currentState = userData.session_state
		if (currentState === "charging" || currentState === "waitingforstation" || currentState === "ready" || currentState === "ended"){
			history.push("/session");
		}
		// otherwise, use this page
		//else {
		//	history.push("/");
		//}
	}, [userData])
	
	
	//Update local state upon retrieval
	useEffect(() => {
		userName = userData.name;
	}, [userData])

	
	

	// FIXME: Need to set a warning if we have a broken state
    const handleClick = useCallback( () => {
		if (id !== "0" && id !== "unknown") {
			history.push("/schedule");
		} else {
            setAlert(true);
            setTimeout(()=>{setAlert(false)},5000);
        }
    }, [history]);

    return(
        <Container className={"startPage"} fluid={true}>
			<Alert className={"startAlert"} isOpen={alert} color="danger">No user defined, try loging in usig the original link</Alert>
            <Row style={{marginTop: "2rem"}}>
                <Col>
                    <h1 className={"responsiveTitle"}>
                        Hello, { userData.name }!
                    </h1>
                </Col>
            </Row>
            <Row className={"carRow"} style={{alignContent: "center", width: "100%", height: "100%"}}>
                <Col>
                    <img className={"carStyle"}
                         src={EvCar}
                         alt="Generic car"
                    />
                </Col>
            </Row>
            <Row className={"startRow3"}>
                <Col>
                    <h4 className={"responsiveSubtitle"}>
                        Ready?
                    </h4>
                </Col>
            </Row>
            <Row className={"startRow4"}>
                <Col>
                    <GlobalButton text={"Set"} onClick={handleClick}/>
                </Col>
            </Row>
        </Container>
    )
}
