/**
 * Used for selecting the required energy with a slider.
 * @module
 */
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import '../../assets/profile-steering/Conversion';
import { WhToKm } from "../../assets/profile-steering/Conversion";
import '../../styles/schedule.scss';
import { useFormContext, useWatch } from 'react-hook-form';
import StyledSlider from '../styled/StyledSlider';
import {MdAutorenew} from "react-icons/all";
import * as Types from "../../App";

import UserProfile from "../../data/models/UserProfile";
import { setCookie, getCookie } from "../../data/Cookies";
import useUserData from "../../hooks/useUserData.js";

interface EnergySelectorProps {
    settings: Types.SessionType;
    setSettings: React.Dispatch<React.SetStateAction<Types.SessionType>>,
}
/**
 * 
 * @returns 
 */
export default function EnergySelector() {
    const context = useFormContext();
    const energy = useWatch({control: context.control, name: "desiredEnergy"});
	
	// Retrieve info from server
	const { userData, getUserData } = useUserData();
	let id = getCookie("id");
	
	let capacity = 100000;
	let whperkm = 1;
	
	useEffect(() => {
		getUserData(id);
    }, [id])
	
    // The component body
    return(
        <Container className="energySelector">
            <Row className="energyBar" style={{width: "100%"}}>
                <Col>
                    <StyledSlider
                        name={"desiredEnergy"} 
                        className="slider" 
                        step={500} 
                        min={0} 
                        max= { userData.ev_capacity } />
                </Col>
            </Row>
            <Row className="conversionText" style={{marginBottom: "1rem"}}>
                <Col>
                    {energy/1000} kWh <MdAutorenew/> {Math.round(energy / userData.ev_consumption)} km
                </Col>
            </Row>
        </Container>
    );
};
