import React, {useCallback, useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { Table, Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import EvCar from '../../assets/ev_car.svg';
import { GlobalButton } from '../styled/Button';
import Stats from '../widgets/Stats';
import ProgressBar from '../widgets/ProgressBar';
import '../../styles/chargingSession.scss';
//import useProgress from "../../hooks/useProgress.js";
import * as Types from "../../App";

import axios from 'axios';

import { setCookie, getCookie } from "../../data/Cookies";
import useUserData from "../../hooks/useUserData.js";


/**
 * Type for ChargingSession component props.
 */
interface ChargingSessionProps {
    settings: Types.SessionType;
}

/**
 * ChargingSession component of the web application. 
 * @param settings Type of Charging Session based on the user input from Schedule page.
 */
export default function ChargingSession({ settings }: ChargingSessionProps) {
    const history = useHistory();

	
    /**
     * Default value of pop-up.
     */
    const [modal, setModal] = useState(false);
    const mode = settings.mode;

	//Gerwin stuff
	// Retrieve info from server
	const { userData, getUserData } = useUserData();
	let id = getCookie("id");

    /**
     * The user confirms the abortion of the session.
     */
    const handleYes = useCallback(() => {

        /**
         * Modal is set to default value.
         */
        setModal(!modal);

		//FIXME change url
		//Send cancelation to DEMKit
		axios.post('https://slimpark.roaming.utwente.nl:3001/cancel', {
			"cancelation":  true,
			"id": getCookie("id"),
		  })
		  .then(function (response) {
			console.log(response);
		  })
		  .catch(function (error) {
			console.log(error);
		  });

        /**
         * The user goes to Feedback page.
         */
        history.push("/feedback");
    }, [modal, setModal, history]);

    /**
     * The user cancels the abortion and remains on ChargingSession page.
     */
    const handleCancel = useCallback(() => setModal(!modal), [modal, setModal]);
    
    /**
     * Default values of elements from ChargingSession page.
     */
    const [sessionTitle, setSessionTitle] = useState<string>("Departure at ");
    const [button, setButton] = useState<string>("Stop");
    const [finished, setFinished] = useState<boolean>(false);
    
    /**
     * Departure time
     * @param settings.hour     Hour set by user for departure.
     * @param settings.minutes  Minutes set by user for departure.
     */
    const [time, setTime] = useState<string>(settings.hour + " : " + settings.minutes);
    
    /**
     * Value of progress bar set.
     */
    //const { progress, getProgress } = useProgress();


    const handleClick = useCallback(() => {
        
        /**
         * if button===finished, the user goes to Feedback page.
         */
        if (finished) {
            history.push("/feedback");
        }  else {

            /**
             * Modal becomes true, pop-up appears.
             */
            setModal(!modal);
        }
    }, [history, finished, modal]);

    /**
     * Updates the value of progress bar.
     */
	// Get data info from server
	useEffect(() => {
		getUserData(id);
		const interval = setInterval(() => {
			getUserData(id)}
			, 10000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    /**
     * Progress bar is at 100%, the charging has finished.
     */
    useEffect(() => {
        if(userData.session_progress === 100){
            setFinished(true);
            setTime("");
            setSessionTitle("Charging finished!");
            setButton("Finish");
        }
    }, [userData, finished, time, sessionTitle, button])

	useEffect(() => {
		if (!finished) {
			if (userData.session_minutes > 9){
				setTime(userData.session_hours+":"+userData.session_minutes);
			} else {
				setTime(userData.session_hours+":0"+userData.session_minutes);
			}
		}
    }, [userData])
	

    return(
        <Container className="chargingSession">
            <Row style={{marginTop: "2rem"}}>
                <Col>
                    <h1 className="chargingTitle">{sessionTitle} {time}</h1>
                </Col>
            </Row>
            <Row style={{alignContent: "center", width: "100%"}}>
                <Col>
                    <img className={"carStyle"}
                        src={EvCar}
                        alt="Generic car"/>
                </Col>
            </Row>
            <Row className={"w-100"}>
                <Col>
                    <ProgressBar
                        value={ userData.session_progress }/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container className="statsContainer">
						<Row>
							<Col>
								<h1 className={"statsTitle"} style={{fontSize: "2.5vh"}} >Progress</h1>
							</Col>
						</Row>  
						<Row>
							<Col>
								<Table>
									<tbody>
									<tr> 
										<td style={{fontSize: "1.5vh", textAlign: "right"}}>Energy:</td>
										<td style={{fontSize: "1.5vh"}}>{ Math.round(userData.session_energy / 100)/10 } of { Math.round(userData.session_energy_target / 100)/10 } kWh</td>
									</tr>
									<tr>
										<td style={{fontSize: "1.5vh", textAlign: "right"}}>Distance:</td>
										<td style={{fontSize: "1.5vh"}}>{ userData.session_kilometers } of { userData.session_kilometers_target } km</td>
									</tr>
									<tr>
										<td style={{fontSize: "1.5vh", textAlign: "right"}}>Power:</td>
										<td style={{fontSize: "1.5vh"}}>{ userData.session_power } Watt</td>
									</tr>

									<tr>
										<td style={{fontSize: "1.5vh", textAlign: "right"}}>Mode:</td>
										<td style={{fontSize: "1.5vh"}}>{ userData.session_modus }</td>
									</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
					</Container>
                </Col>
            </Row>
            <Row style={{marginBottom: "1rem"}}> 
                <Col>
                    <GlobalButton text={button} onClick={handleClick}/>
                </Col>
            </Row>
                    <Modal isOpen={modal}>
                        <ModalHeader style={{background: "rgba(255, 255, 255, 0.5)"}}>Confirmation</ModalHeader>
                        <ModalBody style={{background: "rgba(255, 255, 255, 0.5)"}}>
                            Are you sure you want to abort the charging session?
                        </ModalBody>
                        <ModalFooter style={{background: "rgba(255, 255, 255, 0.5)"}}>
                            <Button className="yesButton" onClick={handleYes}>Yes</Button>
                            <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
        </Container>
    );
};

/*
<Container className="chargingSession">
            <Row style={{marginTop: "2rem"}}>
                <Col>
                    <h1 className="chargingTitle">{sessionTitle} {userData.session_hours}:{userData.session_minutes} </h1>
                </Col>
            </Row>
            <Row style={{alignContent: "center", width: "100%"}}>
                <Col>
                    <img className={"carStyle"}
                        src={EvCar}
                        alt="Generic car"/>
                </Col>
            </Row>
            <Row className={"w-100"}>
                <Col>
                    <ProgressBar
                        value={ userData.session_progress }/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Stats mode={mode}/>
                </Col>
            </Row>
            <Row style={{marginBottom: "1rem"}}> 
                <Col>
                    <GlobalButton text={button} onClick={handleClick}/>
                </Col>
            </Row>
                    <Modal isOpen={modal}>
                        <ModalHeader style={{background: "rgba(255, 255, 255, 0.5)"}}>Confirmation</ModalHeader>
                        <ModalBody style={{background: "rgba(255, 255, 255, 0.5)"}}>
                            Are you sure you want to abort the charging session?
                        </ModalBody>
                        <ModalFooter style={{background: "rgba(255, 255, 255, 0.5)"}}>
                            <Button className="yesButton" onClick={handleYes}>Yes</Button>
                            <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
        </Container>
		*/
