var UserProfile = (function() {
  var name = "Unknown";
  var id = "0";
  var ev_powers = [0];
  var ev_consumption = 194;
  var ev_capacity = 0;
  var session_state = "unknown";
  var session_progress = 0;
  var session_energy = 0;
  var session_power = 0;
  var session_kilometers = 0;
  var session_energy_target = 0;
  var session_kilometers_target = 0;
  var session_modus = "unknown";
  var session_minutes = 0;
  var session_hours = 0;
  
  //variable to keep the state
  var initialized = false;

  var getName = function() {
    return name;  
  };

  var setName = function(username) {
    name = username;   
	localStorage.setItem("name", name);	
  };
  
  var getId = function() {
    return id;  
  };

  var setId = function(identifier) {
    id = identifier;     
	localStorage.setItem("id", identifier);	
  };
  
  
  var getEvPowers = function() {
	return ev_powers; 
  };
  
  var setEvPowers = function(powers) {
	ev_powers = powers; 
	localStorage.setItem("ev_powers", powers);	
  };
  
  var getEvConsumption = function() {
	return ev_consumption; 
  };
  
  var setEvConsumption = function(consumption) {
	ev_consumption = consumption; 
	localStorage.setItem("ev_consumption", consumption);	
  }; 
  
  var getEvCapacity = function() {
	return ev_capacity; 
  };
  
  var setEvCapacity = function(capacity) {
	ev_powers = capacity; 
	localStorage.setItem("ev_capacity",capacity);	
  };
 
  
  var getSessionState = function() {
	return session_state;
  };	  
  
  var setSessionState = function(state) {
	session_state = state;
	localStorage.setItem("session_state", state);
  };
  
  var getSessionProgress = function() {
	return session_progress;
  };	  
  
  var setSessionProgress = function(progress) {
	session_progress = progress;
	localStorage.setItem("session_progress", progress);
  };
  
  var getSessionEnergy = function() {
	return session_energy;
  };	  
  
  var setSessionEnergy = function(energy) {
	session_energy = energy;
	localStorage.setItem("session_energy", energy);
  };

  var getSessionPower = function() {
	return session_power;
  };	  
  
  var setSessionPower = function(power) {
	session_power = power;
	localStorage.setItem("session_power", power);
  };

  
  var getSessionKm = function() {
	return session_kilometers;
  };	  
  
  var setSessionKm = function(kilometers) {
	session_kilometers = kilometers;
	localStorage.setItem("session_kilometers", kilometers);
  };
  
  var getSessionEnergyTarget = function() {
	return session_energy_target;
  };	  
  
  var setSessionEnergyTarget = function(energy) {
	session_energy_target = energy;
	localStorage.setItem("session_energy_target", energy);
  };
  
  var getSessionKmTarget = function() {
	return session_kilometers_target;
  };	  
  
  var setSessionKmTarget = function(kilometers) {
	session_kilometers_target = kilometers;
	localStorage.setItem("session_kilometers_target", kilometers);
  };
  
  var getSessionModus = function() {
	return session_modus;
  };	  
  
  var setSessionModus = function(modus) {
	session_modus = modus;
	localStorage.setItem("session_modus", modus);
  };
  
  var getSessionMinutes = function() {
	return session_minutes;
  };	  
  
  var setSessionMinutes = function(minutes) {
	session_minutes = minutes;
	localStorage.setItem("session_minutes", minutes);
  };
  
  var getSessionHours = function() {
	return session_hours;
  };	  
  
  var setSessionHours = function(hours) {
	session_hours = hours;
	localStorage.setItem("session_hours", hours);
  };
  
  var getInitialized = function() {
	return initialized;  
  };
  
  var setInitialized = function(init) {
	initialized = init;
	localStorage.setItem("session_init", init);
  };
	
  return {
    getName: getName,
    setName: setName,
	getId: getId,
    setId: setId,
	getEvPowers: getEvPowers,
	setEvPowers: setEvPowers,
	getEvConsumption: getEvConsumption,
	setEvConsumption: setEvConsumption,
	getEvCapacity: getEvCapacity,
	setEvCapacity: setEvCapacity,
	getSessionState: getSessionState,
	setSessionState: setSessionState,
	getSessionProgress: getSessionProgress,
	setSessionProgress: setSessionProgress,
	getSessionEnergy: getSessionEnergy,
	setSessionEnergy: setSessionEnergy,
	getSessionPower: getSessionPower,
	setSessionPower: setSessionPower,
	getSessionKm: getSessionKm,
	setSessionKm: setSessionKm,
	getSessionEnergyTarget: getSessionEnergyTarget,
	setSessionEnergyTarget: setSessionEnergyTarget,
	getSessionKmTarget: getSessionKmTarget,
	setSessionKmTarget: setSessionKmTarget,
	getSessionModus: getSessionModus,
	setSessionModus: setSessionModus,
	getSessionMinutes: getSessionMinutes,
	setSessionMinutes: setSessionMinutes,
	getSessionHours: getSessionHours,
	setSessionHours: setSessionHours,
	getInitialized: getInitialized,
	setInitialized: setInitialized
  }

})();

export default UserProfile;